$(document).on("click", "#reload-products-btn", ->
  startSpinner($(this), "Reloading...")

  $("#reload-products-btn").attr("disabled", true)

  $.ajax "/products/reload",
    type: "GET",
    dataType: "script"
  .always (data, textStatus, jqXHR) ->
    $("#reload-products-btn").removeAttr("disabled")
)

$(document).on("click", ".detach-entitlement-btn", ->
  $(this).closest("#product-form .entitlement").remove()
)

$(document).on("click", "#new-entitlement-btn", ->
  $("#new-entitlement-btn").hide()
  $("#product-form #new-entitlement").show()
)
