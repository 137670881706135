window.scrollToLogEnd = (el) ->
  #el = $("#log_content")
  el.scrollTop(el.prop("scrollHeight"))

max_countdown = 10
countdown = max_countdown

displayTimer = ->
  $(".log-timer").text(countdown)

refreshLogContent = ->
  if $(".log-timer").length
    $.ajax({
      type: "POST",
      url: "/import/refresh_log_content",
      dataType: "script"
    })

runTimer = ->
  interval = setInterval((->
    countdown -= 1
    if countdown < 0
      countdown = max_countdown
      refreshLogContent()
    displayTimer()
  ), 1000)



$(document).ready(runTimer)

$(document).ready(displayTimer)

$(document).on("ready turbolinks:load", ->
  $(".log-content").each (index, element) =>
    scrollToLogEnd($(element))
)

$(document).on("shown.bs.tab", ".import-tab", ->
  target = $(this).find("button").attr("data-bs-target")
  el = $(".log-content", "#import-panel " + target)
  scrollToLogEnd(el);
)
