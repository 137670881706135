$(document).on("click", "#select-all-btn", ->

  checked = $(this).prop("checked")
  $(".select-btn").prop("checked", checked)

  if checked
    $("#bulk-btn-container").show()
  else
    $("#bulk-btn-container").hide()
)

$(document).on("click", ".select-btn", ->

  if $(".select-btn:checked").length
    $("#bulk-btn-container").show()
  else
    $("#bulk-btn-container").hide()
)

$(document).on("change", "#photo-status-selector", ->

  status = $(this).find("option:selected").val()

  # redirect to page with filled 's' parameter
  window.location.href = "/photos?s=#{status}"
)
