import Rails from "@rails/ujs";

//const nativeConfirm = Rails.confirm;

let __SkipConfirmation = false;

Rails.confirm = function (message, element) {
  if (__SkipConfirmation) {
    return true;
  }

  /*
  const dialogId = element.getAttribute('data-confirm-dialog');
  if (!dialogId) {
    return nativeConfirm(message, element);
  }
  */

  function onConfirm() {
    __SkipConfirmation = true;
    element.click();
    __SkipConfirmation = false;
  }

  showDialog(element, onConfirm);
  return false;
};

function showDialog(element, onConfirm) {
  const options = JSON.parse(element.getAttribute('data-sweet-alert') || '{}');
  const message = element.getAttribute('data-confirm');
  var title = $(element).data("confirm-title");
  if (!title) { title = 'Are you sure?' }

  Swal.fire({
    title: title,
    html: message,
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    confirmButtonColor: '#0d6efd',
    //cancelButtonColor: '#d33',
    customClass: {
      confirmButton: "btn btn-primary btn-md",
      cancelButton: "btn btn-default btn-md"
    }
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    }
  });
}
