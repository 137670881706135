$(document).on("ready turbolinks:load", ->

  # non-hover triggered popovers
  $('[data-bs-toggle="popover"]').popover({
    html: true,
    container: 'body'
  })
)

# hide all others not clicked popovers
$(document).on("click", '[data-bs-toggle="popover"]', (e) ->
  $('[data-bs-toggle="popover"]').not(this).popover("hide")
)

# hide all popovers in clicked somewhere else
$(document).on("click", "html", (e) ->
  if (!$(e.target).closest("span").hasClass("dismissable-popup") and !$(e.target).closest(".popover").length)
    $(".dismissable-popup").popover("hide");
)
